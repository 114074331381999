import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Bounce from "react-reveal/Bounce"
import Pulse from "react-reveal/Pulse"
import {
  FaMusic,
  FaDollarSign,
  FaSitemap,
  FaRegThumbsUp,
  FaArrowRight,
} from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import imgKaterhineEmeneth from "../images/review-katherine-emeneth.jpg"
import imgDanBio from "../images/dan-home-bio.jpg"

import "./index.styles.scss"

const IndexPage = () => {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter + 1)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Layout className="home">
      <Seo title="Home" />
      <section className="hero home__hero is-medium">
        <div className="container hero-body">
          <h1 className="title is-size-2-mobile is-size-1-tablet has-text-shadow">
            Enhance Your Private Music Studio Offerings
          </h1>
          <h2 className="subtitle is-size-5-mobile is-size-4-tablet has-text-shadow">
            Build a thriving program without experiencing stress and mistakes
            along the way
          </h2>
        </div>
      </section>
      <section className="home__courses container">
        <div className="content has-text-centered pb-6">
          <h3 className="mb-2 is-size-3-mobile is-size-2-tablet">What's Covered?</h3>
        </div>
        <div className="columns courses__content">
          <div className="column is-one-third">
            <FaMusic />
            <h4>Building Community</h4>
            <span>
              Studio ensembles are the perfect way to keep your students
              engaged and coming back for more.
            </span>
          </div>
          <div className="column is-one-third">
            <FaDollarSign />
            <h4>Creating Profit</h4>
            <span>
              Who doesn't want to make more money and teach fewer hours? Learn
              how you can take a full month off and still get paid!
            </span>
          </div>
          <div className="column is-one-third">
            <FaSitemap />
            <h4>Getting Organized</h4>
            <span>
              Discover spreadsheets, templates, and guides to make private
              studio management easier.
            </span>
          </div>
        </div>
        <div className="columns is-centered mt-4">
          <div className="column is-half">
            <Link
              to="/guides"
              className="button button--featured is-large is-fullwidth is-primary is-outlined"
            >
              Guides to Enhance Your Studio Offerings
            </Link>
          </div>
        </div>
      </section>
      <section className="has-background-grey-lighter pb-6">
        <div className="container">
          <div className="content has-text-centered pt-6 pb-4">
            <h3 className="mb-2">What People Are Saying</h3>
          </div>
          <div className="columns is-centered">
            <div className="column is-full-mobile is-half-tablet">
              <div className="card">
                <div className="card-content">
                  <div className="content is-size-6">
                    <div className="styled-quote-symbol has-text-primary">
                      &ldquo;
                    </div>
                    <p>
                      Dan Parasky's Studio Ensemble Revolution is THE solution
                      if you're interested in creating an ensemble program for
                      your lessons studio whether it is online or in person. Dan
                      clearly lays out the step-by-step system of how to get
                      students interested in participating in an ensemble
                      program as well as how to structure fees, go about finding
                      a rehearsal space, and so much more. He also covers how
                      having an ensemble program helps retention and creates
                      studio culture.
                    </p>
                    <p>
                      If you're looking for a way to add extra income to your
                      studio endeavors and love chamber music, I definitely
                      recommend taking Studio Ensemble Revolution. You won't
                      regret it!
                    </p>
                  </div>
                  <div className="media is-align-items-center">
                    <div className="media-left">
                      <figure className="image is-64x64 m-0">
                        <img
                          src={imgKaterhineEmeneth}
                          alt="Katherine Emeneth"
                          width="64"
                          height="64"
                        />
                      </figure>
                    </div>
                    <div className="media-content is-size-6" style={{ overflow: 'hidden' }}>
                      <p className="title is-5">Katherine Emeneth</p>
                      <p className="subtitle is-7">
                        creator - The Music Teacher’s Playbook
                      </p>
                    </div>
                    <div className="media-right">
                      <div className="styled-quote-symbol has-text-primary">
                        &rdquo;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-info py-6">
        <div className="container">
          <Bounce left>
            <div className="magnet has-border-bottom-primary-2">
              <div className="magnet__icon">
                <FaRegThumbsUp className="is-size-1 mr-4" />
              </div>
              <div className="magnet__content">
                <h3 className="is-size-4 mb-2">My Favorite 75</h3>
                <p className="is-size-6">
                  See my favorite 75 pieces written for flute ensemble. You and
                  your students will love them!
                </p>
              </div>
              <div className="magnet__action">
                <Pulse spy={counter}>
                  <Link
                    className="button is-primary is-medium mt-2"
                    to="/get-access/favorite-75-flute-ensemble-pieces"
                  >
                    Get Instant Access
                  </Link>
                </Pulse>
              </div>
            </div>
          </Bounce>
        </div>
      </section>
      <section className="index__bio container">
        <div className="columns is-centered">
          <div className="column is-one-third is-flex is-align-items-center is-justify-content-center">
            <figure className="image mt-2 is-75-percent-mobile-xl">
              <img
                className="bio__pic is-rounded has-box-shadow"
                src={imgDanBio}
                alt="Dan Parasky, Instructor"
              />
            </figure>
          </div>
          <div className="column is-flex is-align-items-center">
            <div className="bio__content content is-size-6 container--text">
              <h2>Hello, I’m Dan!</h2>
              <p>
                Running the Parasky Flute Studios has always been my thing. Most
                of us start out with the dream of playing professionally in an
                orchestra but I knew incredibly early on that owning a private
                studio was truly my dream job!
              </p>
              <p>
                As my teaching skills were developing, I started to reflect on
                how formal university music programs do not actually teach us
                exactly how to run, teach and manage a private music studio.
              </p>
              <p>
                This led to years of experimenting with different methods to run
                my studio and connect with my students to create an optimized
                private music studio.
              </p>
              <div className="is-flex is-flex-wrap-wrap">
                <Link
                  to="/about"
                  className="button is-primary mr-4 mb-3 arrow-right-enlarge is-size-7-mobile is-size-6-tablet"
                >
                  Learn more about me <FaArrowRight className="ml-3" />
                </Link>
                <Link
                  to="/guides"
                  className="button is-primary mb-3 is-outlined arrow-right-enlarge is-size-7-mobile is-size-6-tablet"
                >
                  Guides{" "}
                  <FaArrowRight className="ml-3" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
